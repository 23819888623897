import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content-full-width'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from 'components/new/spacer'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Disclaimer from 'components/landing-page/disclaimer'
import formatPrice from 'utils/format-price'
import pmt from 'utils/pmt'
import { Carousel as BaseCarousel } from 'components/new/carousel'
import Button from 'components/button/button'
import AspectRatioContainer from 'components/aspect-ratio-container'
import { Column, Grid } from 'components/grid'
import { useToggle } from 'hooks/use-toggle'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'

const Carousel = ({ openQuoteForm, products }) => {
  return (
    <ProductCarousel
      breakPoints={[
        { width: 1, itemsToShow: 1 },
        { width: 600, itemsToShow: 2, itemsToScroll: 2 },
        { width: 900, itemsToShow: 3, itemsToScroll: 3 },
        { width: 1200, itemsToShow: 4, itemsToScroll: 4 },
      ]}
      showEmptySlots={true}
      kind='dark'
    >
      {products.map(product => (
        <ProductCarouselCard {...product} openQuoteForm={openQuoteForm} key={product.id} />
      ))}
    </ProductCarousel>
  )
}

const ProductCarousel = styled(BaseCarousel)`
  .rec-item-wrapper {
    height: 100%;
  }
`

const ProductCarouselCard = props => {
  const {
    name,
    openQuoteForm,
    sanityData: { categoryCode, subcategoryCode, firstImage, highlights, options, showPrice },
    slug,
    tabIndex,
  } = props

  const minPrice = showPrice
    ? options.reduce((minPrice, option) => {
        if (!minPrice || option.price < minPrice) {
          return option.price
        }
        return minPrice
      }, null)
    : 0

  return (
    <StyledProductCarouselCard className='card'>
      <AspectRatioContainer className='card-image-container'>
        {firstImage ? (
          <GatsbyImage
            image={firstImage.asset.gatsbyImageData}
            alt={name}
            objectFit='contain'
            objectPosition='50% 50%'
            style={{ height: '100%' }}
          />
        ) : (
          <MissingImage />
        )}
        <HiddenLink to={slug} />
      </AspectRatioContainer>
      <div className='card-text'>
        <h3 className='card-title'>
          {name}
          <HiddenLink to={slug} />
        </h3>
        {Array.isArray(highlights) && highlights.length > 0 ? (
          <ul className='card-highlights'>
            {highlights.map(highlight => (
              <li key={highlight}>{highlight}</li>
            ))}
          </ul>
        ) : null}
        {showPrice ? (
          <ul className='card-options'>
            {options.map(option => (
              <li key={option.title}>
                <span className='card-option-title'>{option.title}</span>
                <span className='card-option-price'>
                  {formatPrice(pmt(0, 60, option.price), {
                    round: true,
                  })}
                  /mo
                </span>
              </li>
            ))}
          </ul>
        ) : null}
        <CardButton
          data-title={name}
          data-price={minPrice}
          data-category-code={categoryCode}
          data-subcategory-code={subcategoryCode}
          onClick={openQuoteForm}
          tabIndex={tabIndex}
        >
          {showPrice ? 'Talk to sales' : 'Get a quote'}
        </CardButton>
      </div>
    </StyledProductCarouselCard>
  )
}

const StyledProductCarouselCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 8px 16px;
  padding: 24px;
  position: relative;
  width: 100%;

  .card-image-container {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin-top: 24px;
  }

  .card-title {
    display: inline-block;
    font-size: 21px;
    font-weight: 700;
    margin: 0 auto;
    max-width: none;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: none;

    :hover,
    :focus {
      color: ${props => props.theme.color.g400};
      text-decoration: underline;
    }
  }

  .card-highlights {
    list-style: none;
    margin: 16px 0 8px;
    padding: 0;

    li {
      border-top: 1px solid ${props => props.theme.color.n30};
      color: ${props => props.theme.color.n100};
      font-size: 1rem;
      margin: 0;
      padding: 8px 0;
      text-align: center;

      :last-child {
        border-bottom: 1px solid ${props => props.theme.color.n30};
      }
    }
  }

  .card-options {
    flex-grow: 2;
    line-height: 1.35;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      padding: 0;
    }

    .card-option-title {
      font-weight: 600;
      margin-right: 8px;
    }
  }
`

const MissingImage = () => {
  return (
    <StyledMissingImage>
      <span>Photo coming soon</span>
    </StyledMissingImage>
  )
}

const StyledMissingImage = styled.div`
  background-color: ${props => props.theme.color.n20};
  height: 100%;
  position: relative;
  width: 100%;

  span {
    color: ${props => props.theme.color.n400};
    display: block;
    font-size: 14px;
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
  }
`

const HiddenLink = ({ to }) => {
  return (
    <Link
      to={to}
      aria-hidden='true'
      tabIndex='-1'
      style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
    />
  )
}

const CardButton = styled(Button)`
  align-self: flex-end;
  margin: 16px auto 0;
  padding: 6px;
  text-decoration: none;
`

const BlackFridayLandingPage = props => {
  const {
    data: {
      compactTractorProducts,
      heroImage,
      x700SeriesProducts,
      xuv800SeriesProducts,
      z700SeriesProducts,
    },
    data,
  } = props

  const [open, toggleModal] = useToggle(false)
  const [selectedProduct, setSelectedProduct] = useState()

  useEffect(() => {
    if (window && window.dataLayer && selectedProduct) {
      window.dataLayer.push({
        'product-price': selectedProduct.price || 0,
      })
    }
  }, [selectedProduct])

  const openQuoteForm = e => {
    const target = e.target
    const title = target.getAttribute('data-title')
    const price = target.getAttribute('data-price')
    const categoryCode = target.getAttribute('data-category-code')
    const subcategoryCode = target.getAttribute('data-subcategory-code')
    setSelectedProduct({
      title,
      price,
      categoryCode,
      subcategoryCode,
    })
    toggleModal(true)
  }

  return (
    <Layout>
      <Helmet>
        <title>Black Friday Turns Green | Hutson Inc</title>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Hero>
        <HeroImageContainer>
          <HeroText>
            <Title>Black Friday Turns Green</Title>
          </HeroText>
          <HeroOverlay />
          <HeroImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt=''
            objectFit='cover'
            objectPosition='75% 50%'
          />
        </HeroImageContainer>
      </Hero>
      <DarkBackground>
        <Content>
          <H2 className='section-title'>
            0% for 60 months on select mowers, Gators, and compact utility tractors
          </H2>
          <P className='section-details'>
            Take advantage of Black Friday financing from John Deere Financial now through January
            2, 2025.
          </P>
          <Spacer size='xl' />
          <H3 className='section-title'>3R, 4M & 4R Series Compact Utility Tractors</H3>
          <Spacer size='m' />
          <Carousel products={compactTractorProducts.nodes} openQuoteForm={openQuoteForm} />
          <Spacer size='xl' />
          <H3 className='section-title'>X700 Series Garden Tractors</H3>
          <Spacer size='m' />
          <Carousel products={x700SeriesProducts.nodes} openQuoteForm={openQuoteForm} />
          <Spacer size='xl' />
          <H3 className='section-title'>Z760R Zero-Turn Mower</H3>
          <Spacer size='m' />
          <Grid>
            {z700SeriesProducts.nodes.map(product => (
              <Column columns='1/2' key={product.id}>
                <ProductCarouselCard {...product} openQuoteForm={openQuoteForm} />
              </Column>
            ))}
          </Grid>
          <Spacer size='xl' />
          <H3 className='section-title'>
            XUV 845R, XUV 875R, XUV 835R & XUV 865R Gators Utility Vehicles
          </H3>
          <Spacer size='m' />
          <Carousel products={xuv800SeriesProducts.nodes} openQuoteForm={openQuoteForm} />
          <Spacer size='xl' />
          <Disclaimer>
            <span style={{ color: '#efefef' }}>
              Offers valid November 26, 2024 through January 2, 2025 while supplies last. Prices and
              availability may vary by dealer. Financing subject to approved credit by John Deere
              Financial. Down payment may be required to receive financing. Images for illustration
              purposes only. Residency restrictions, terms and conditions may apply. See dealer for
              details.
            </span>
          </Disclaimer>
        </Content>
      </DarkBackground>
      <Modal toggle={toggleModal} isOpen={open}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={`Black Friday Turns Green${
            selectedProduct ? ` - ${selectedProduct.title}` : ''
          }`}
          productPrice={selectedProduct ? selectedProduct.price : 0}
          productType='john-deere'
          submitButtonClass='submit-quote'
          pageURL='https://www.hutsoninc.com/lp/black-friday/'
          hiddenValues={[
            ...(selectedProduct && selectedProduct.categoryCode
              ? [
                  {
                    name: 'Category Code',
                    value: selectedProduct.categoryCode,
                  },
                ]
              : []),
            ...(selectedProduct && selectedProduct.subcategoryCode
              ? [
                  {
                    name: 'Subcategory Code',
                    value: selectedProduct.subcategoryCode,
                  },
                ]
              : []),
          ]}
          header={selectedProduct && selectedProduct.price ? 'Talk to sales' : 'Get a quote'}
          equipmentBrand='John Deere'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const DarkBackground = styled.div`
  background-color: ${props => props.theme.color.n900};

  .section-title,
  .section-details {
    color: #fff;
  }
`

const Hero = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    height: 500px;
  }
`

const HeroImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const HeroOverlay = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: 600px) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  }
`

const HeroText = styled(Content)`
  color: #fff;
  left: 50%;
  padding-bottom: 0;
  position: absolute;
  bottom: 10%;
  transform: translateX(-50%);
  z-index: 2;
`

const HeroImage = styled(GatsbyImage)`
  min-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`

const Title = styled.h1`
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 48px;
  }
`

export const pageQuery = graphql`
  query blackFridayLandingPage {
    heroImage: file(relativePath: { eq: "landing-pages/black-friday-hero.jpg" }) {
      ...FullWidthImage
    }
    compactTractorProducts: allDeereProduct(
      filter: {
        sku: {
          in: [
            "3033rlv"
            "3039rlv"
            "3046rlv"
            "4044mlv"
            "4052mlv"
            "4052hlv"
            "4066mlv"
            "4066hlv"
            "4044rlv"
            "4052rlv"
            "4066rlv"
            "4075rlv"
          ]
        }
      }
    ) {
      nodes {
        id
        name
        sanityData {
          categoryCode
          subcategoryCode
          firstImage {
            asset {
              gatsbyImageData(width: 540)
            }
          }
          highlights
          options {
            price
            title
          }
          showPrice
        }
        slug
      }
    }
    x700SeriesProducts: allDeereProduct(
      filter: {
        sanityData: {
          sku: { in: ["x730am", "x734am", "x738am", "x739am", "x750am", "x754am", "x758am"] }
        }
      }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        id
        name
        sanityData {
          categoryCode
          subcategoryCode
          firstImage {
            asset {
              gatsbyImageData(width: 540)
            }
          }
          highlights
          options {
            price
            title
          }
          showPrice
        }
        slug
      }
    }
    z700SeriesProducts: allDeereProduct(
      filter: { sanityData: { sku: { in: ["760r_tc"] } } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        id
        name
        sanityData {
          categoryCode
          subcategoryCode
          firstImage {
            asset {
              gatsbyImageData(width: 540)
            }
          }
          highlights
          options {
            price
            title
          }
          showPrice
        }
        slug
      }
    }
    xuv800SeriesProducts: allDeereProduct(
      filter: { sanityData: { sku: { in: ["845ram", "845rdm", "875ram", "875rdm"] } } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        id
        name
        sanityData {
          categoryCode
          subcategoryCode
          firstImage {
            asset {
              gatsbyImageData(width: 540)
            }
          }
          highlights
          options {
            price
            title
          }
          showPrice
        }
        slug
      }
    }
  }
`

export default BlackFridayLandingPage
